<template>
  <v-dialog
    :value="dialogs.resetLimit"
    width="600"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title class="reset-limit-title">
          Сбросить лимит {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5">
        <p>
          Вы уверены, что хотите сбросить лимит созданных профилей пользователю {{ users.forDialog.user.username }}?
        </p>

        <p>
          В случае сброса лимита, вернуть это действие самостоятельно будет невозможно
        </p>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="resetLimit(users.forDialog.user.teamId)"
        >
          Сбросить лимит
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'UsersDialogResetLimit',

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
      pagination: 'users/pagination',
    }),
    ...mapFields('users', ['topbarSearch']),
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'resetLimit');
    },

    async resetLimit(teamId) {
      try {
        this.loading = true;

        const response = await this.api.post(`admin/users/${teamId}/restore_limit`);

        if (response.status === 200) {
          this.closeDialog();
          await this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Лимит успешно сброшен',
          });

          this.$store.dispatch('users/loadUsers', {
            itemsPerPage: this.pagination.perPage,
            query: this.topbarSearch.searchInput,
            page: this.pagination.currentPage,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.reset-limit-title {
  font-family: Roboto,serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
</style>
