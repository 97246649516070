<template>
  <div>
    <!-- СКОПИРОВАТЬ ID -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="copyToClipboard(user.id, 'User ID скопирован')"
        >
          <v-icon :size="20">
            mdi mdi-identifier
          </v-icon>
        </v-btn>
      </template>
      <span>Копировать User ID</span>
    </v-tooltip>

    <!-- ИЗМЕНИТЬ ТАРИФ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openChangePlan"
        >
          <v-icon :size="20">
            mdi-newspaper-variant-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Изменить тариф</span>
    </v-tooltip>

    <!-- ДОБАВИТЬ ПЛАТЕЖ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="addPayment"
        >
          <v-icon :size="20">
            mdi-currency-usd
          </v-icon>
        </v-btn>
      </template>
      <span>Добавить платеж</span>
    </v-tooltip>

    <!-- ИЗМЕНИТЬ ПАРОЛЬ -->
    <v-tooltip
      v-if="profile.manageUsers"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openChangePassword"
        >
          <v-icon :size="20">
            mdi-key-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Изменить пароль</span>
    </v-tooltip>

    <!-- ИЗМЕНИТЬ ТЕЛЕГРАМ -->
    <v-tooltip
      v-if="profile.manageUsers"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openChangeTelegram"
        >
          <v-icon :size="20">
            mdi-message-processing
          </v-icon>
        </v-btn>
      </template>
      <span>Изменить telegram пользователя</span>
    </v-tooltip>

    <!-- ПЕРЕИМЕНОВАТЬ ПОЛЬЗОВАТЕЛЯ -->
    <v-tooltip
      v-if="profile.manageUsers"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openRenameUser"
        >
          <v-icon> mdi-rename-box </v-icon>
        </v-btn>
      </template>
      <span>Переименовать пользователя</span>
    </v-tooltip>

    <!-- ЗАДАТЬ РЕФКУ  -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="referralCommission"
        >
          <v-icon :size="18">
            mdi-percent
          </v-icon>
        </v-btn>
      </template>
      <span>Реферальная комиссия</span>
    </v-tooltip>

    <!-- ВЫКЛЮЧИТЬ ДВУХФАКТОРНУЮ АУТЕНТИФИКАЦИЮ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          :disabled="!user.twoFactorEnableDisable"
          v-on="on"
          @click="disableTwoFactor"
        >
          <v-icon :size="20">
            mdi mdi-two-factor-authentication
          </v-icon>
        </v-btn>
      </template>
      <span>Выключить двухфакторную аутентификацию</span>
    </v-tooltip>

    <!-- ПОКАЗАТЬ ТРАНЗАКЦИИ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="showTransactions"
        >
          <v-icon :size="20">
            mdi-account-cash
          </v-icon>
        </v-btn>
      </template>
      <span>Показать транзакции</span>
    </v-tooltip>

    <!-- Удалить учётку -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          :disabled="checkDisabled()"
          v-on="on"
          @click="openDeleteUser"
        >
          <v-icon :size="20">
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Удалить учётку</span>
    </v-tooltip>

    <v-btn
      text
      @click="showUserHistory"
    >
      История
    </v-btn>

    <!-- ПОДОЗРИТЕЛЬНЫЙ ВХОД -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openSuspiciousEntranceDialog"
        >
          <v-icon :size="20">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
      <span>Показать подозрительный входы</span>
    </v-tooltip>

    <!-- ВЫКЛЮЧИТЬ ИЛИ ВКЛЮЧИТЬ ГЕО 2FA -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openTwoFactorByEmailEnableOrDisableDialog"
        >
          <v-icon :size="20">
            mdi-account-sync
          </v-icon>
        </v-btn>
      </template>
      <span v-if="user.twoFactorByEmailEnable">
        Включить Гео 2FA
      </span>
      <span v-else>
        Выключить Гео 2FA
      </span>
    </v-tooltip>

    <!-- ПОМЕТИТЬ ТРАНЗАКЦИИ ОПЛАЧЕННЫМИ -->
    <v-tooltip
      v-if="profile.resetReferalBalance"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="payReferal"
        >
          <v-icon :size="20">
            mdi-cash-check
          </v-icon>
        </v-btn>
      </template>
      <span>Пометить транзакции оплаченными</span>
    </v-tooltip>

    <!-- ВОССТАНОВИТЬ ПРОФИЛИ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          :loading="restoreBPLoading"
          v-on="on"
          @click="restoreBrowserProfiles"
        >
          <v-icon :size="20">
            mdi-ghost
          </v-icon>
        </v-btn>
      </template>
      <span>Восстановить фантомные профили, если профилей меньше, чем показывает
        счетчик тарифа</span>
    </v-tooltip>

    <!-- ABUSE ACCOUNT -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openAbuse"
        >
          <v-icon :size="20">
            mdi-lock-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Абьюз</span>
    </v-tooltip>

    <!-- ONE-TIME LOGIN CODE -->
    <v-tooltip
      bottom
      v-if="profile.manageUsers"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="getOneTimeLoginCode"
        >
          <v-icon :size="20">
            mdi-map-marker-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Получить код если "подозрительный вход"</span>
    </v-tooltip>

    <!-- RESET LIMIT -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          :disabled="disableResetLimitButton"
          v-on="on"
          @click="openResetLimitDialog"
        >
          <v-icon :size="20">
            mdi-timelapse
          </v-icon>
        </v-btn>
      </template>
      <span>Сбросить лимит</span>
    </v-tooltip>

    <!-- ПОДТВЕРДИТЬ ЮЗЕРА -->
    <v-btn v-if="!user.email_verified_at" @click="forceVerifyEmail">Подтвердить почту (не подтверждена)</v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clipboard from '../../mixins/clipboard';

export default {
  name: 'UsersMainTableCellActions',

  mixins: [clipboard],

  props: {
    user: {
      type: Object,
      default: () => ({
        id: 0,
      }),
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      restoreBPLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      profile: 'main/profile',
      users: 'users/users',
    }),
    ...mapFields('users', ['userTransactions', 'topbarSearch']),

    disableResetLimitButton() {
      const { plan, browserProfilesLimit } = this.user;
      return plan !== 'free' || browserProfilesLimit !== 10;
    },
  },

  methods: {
    async disableTwoFactor() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'disableTwoFactor',
      });
      this.$store.dispatch('users/openDialog', 'disableTwoFactor');
    },

    async openChangePlan() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'changePlan',
      });

      this.$store.dispatch('users/openDialog', 'changePlan');
    },

    async openSuspiciousEntranceDialog() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'suspiciousEntrance',
      });

      this.$store.dispatch('users/openDialog', 'suspiciousEntrance');
    },

    async openTwoFactorByEmailEnableOrDisableDialog() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'twoFactorByEmailEnableOrDisable',
      });

      this.$store.dispatch('users/openDialog', 'twoFactorByEmailEnableOrDisable');
    },

    async openChangePassword() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'changePassword',
      });

      this.$store.dispatch('users/openDialog', 'changePassword');
    },

    async openChangeTelegram() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'changeTelegram',
      });

      await this.$store.dispatch('users/openDialog', 'changeTelegram');
    },

    async openRenameUser() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'renameUser',
      });

      await this.$store.dispatch('users/openDialog', 'renameUser');
    },

    async forceVerifyEmail() {
      const response = await this.api.post(
        '/admin/users/force-verify-email/',
        { username: this.user.username },
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        await this.$store.dispatch('main/alert', {
          color: 'success',
          message: 'Пользователь подтвержден',
        });
        this.$store.dispatch('users/loadUsers', { query: this.topbarSearch.searchInput });
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось подтвердить пользователя',
        });
      }
    },

    async getOneTimeLoginCode() {
      const response = await this.api.get(
        `/admin/users/one-time-login-code/${this.user.id}`,
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        alert(`Код: ${response.data?.code || JSON.stringify(response.data)}`);
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось получить код',
        });
      }
    },

    async restoreTeamUser(userId) {
      const response = await this.api.post(
        `/admin/users/restore/${userId}`,
        {},
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        await this.$store.dispatch('main/alert', {
          color: 'success',
          message: 'Пользователь и его профили восстановлены',
        });
        this.$store.dispatch('users/loadUsers', { query: this.topbarSearch.searchInput });
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось восстановить пользователя и/или его профили',
        });
      }
    },

    async referralCommission() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'referralCommission',
      });

      this.$store.dispatch('users/openDialog', 'referralCommission');
    },

    async changePassword() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'changePassword',
      });

      this.$store.dispatch('users/openDialog', 'changePassword');
    },

    async changeReferralPercent() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'changeReferralPercent',
      });

      this.$store.dispatch('users/openDialog', 'changeReferralPercent');
    },

    async addPayment() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'addPayment',
      });

      this.$store.dispatch('users/openDialog', 'addPayment');
    },

    async showTransactions() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'showTransactions',
      });

      this.userTransactions = this.user.payments ? this.user.payments : [];
      this.$store.dispatch('users/openDialog', 'showTransactions');
    },

    async openDeleteUser() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'openDeleteUser',
      });

      this.$store.dispatch('users/openDialog', 'openDeleteUser');
    },

    async payReferal() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'payReferal',
      });
      this.$store.dispatch('users/openDialog', 'payReferal');
    },

    async restoreBrowserProfiles() {
      this.restoreBPLoading = true;

      const response = await this.api.patch(
        '/admin/browser_profiles_restore_access',
        { userId: this.user.id },
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        await this.$store.dispatch('main/alert', {
          color: 'success',
          message: `Профилей восстановлено ${response.data?.data?.count || 0}`,
        });
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось восстановить профили',
        });
      }

      this.restoreBPLoading = false;
    },

    async openAbuse() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'abuse',
      });

      this.$store.dispatch('users/openDialog', 'abuse');
    },

    checkDisabled() {
      return !this.profile.deleteTeam || this.userId === this.profile.id;
    },

    async showUserHistory() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'history',
      });

      this.$store.dispatch('users/openDialog', 'history');
    },

    async openResetLimitDialog() {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(this.user),
        dialog: 'resetLimit',
      });
      this.$store.dispatch('users/openDialog', 'resetLimit');
    },
  },
};
</script>
