<template>
  <v-card>
    <!-- ДИАЛОГ ДЛЯ ВОССТАНАВЛЕНИЯ -->
    <BrowserProfileRestoreDialog
      v-model="browserProfileRestoringProfilesDialog"
      :loader="loader"
      @restore="restore(browserProfileData)"
    />
    <!-- ДИАЛОГ С ДАННЫМИ -->
    <v-dialog
      v-model="browserProfileDataDialog"
      width="700"
    >
      <v-card>
        <v-card-title class="text-h5 blue">
          Данные
        </v-card-title>

        <v-card-text class="pt-3">
          <v-textarea
            rows="8"
            outlined
            :value="browserProfileData ? JSON.stringify(browserProfileData) : ''"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="browserProfileDataDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="browserProfiles.selected"
      :items="updatedItems"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      :loading="loading.mainTable"
      show-select
    >
      <!-- ПЕРЕЙТИ К ЛОГАМ -->
      <template #[`item.actions`]="{item}">
        <v-btn
          color="primary"
          outlined
          x-small
          class="mr-1"
          @click="showData(item)"
        >
          Данные
        </v-btn>

        <v-btn
          color="primary"
          outlined
          x-small
          :to="`/logs?browserProfileId=${item.id}`"
          target="_blank"
        >
          Логи &rarr;
        </v-btn>

        <v-btn
          color="primary"
          outlined
          x-small
          @click="forceStop(item.id)"
        >
          Принудительная остановка
        </v-btn>

        <v-btn
          v-if="item.deleted_at !== '_' || item.archived === 1"
          style="margin-left: 5px"
          color="red"
          outlined
          x-small
          class="mr-1"
          @click="showRestoreBrowserProfileDialog(item)"
        >
          Восстановить
        </v-btn>
      </template>

      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BrowserProfileRestoreDialog
  from '@/components/browserProfiles/BrowserProfileRestoreDialog.vue';

export default {
  name: 'BrowserProfilesMainTable',
  components: { BrowserProfileRestoreDialog },

  data() {
    return {
      browserProfileDataDialog: false,
      browserProfileRestoringProfilesDialog: null,
      browserProfileData: null,
      deleted_at: null,
      archived: 0,
      loader: false,
      env: 'prod',
    };
  },

  computed: {
    ...mapGetters({
      loading: 'browserProfiles/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'browserProfiles/pagination',
      topbarSearch: 'browserProfiles/topbarSearch',
    }),
    ...mapFields('browserProfiles', ['browserProfiles', 'searchParams']),

    cols() {
      const cols = [];

      cols.push({ text: 'Название', value: 'name', width: 400 });
      cols.push({
        text: 'Действия', value: 'actions',
      });
      cols.push({
        text: 'Создан', value: 'created_at',
      });
      cols.push({
        text: 'Удалён', value: 'deleted_at',
      });
      cols.push({
        text: 'Обновлён', value: 'updated_at',
      });
      return cols;
    },

    updatedItems() {
      return this.browserProfiles.all.map((item) => ({
        ...item,
        deleted_at: item.deleted_at == null ? '_' : item.deleted_at,
      }));
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    updateFooterOptions(options) {
      this.searchParams.page = options.page;
      this.searchParams.itemsPerPage = options.itemsPerPage;
      this.$store.dispatch('browserProfiles/loadBrowserProfiles');
    },

    async forceStop(id) {
      try {
        this.loader = true;
        const data = {
          id,
        };

        const response = await this.api.post('/admin/browser_profiles/force-stop', data);

        if (response.status === 200) {
          await this.$store.dispatch('main/alert', { color: 'success', message: 'Профиль остановлен' });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        await this.$store.dispatch('main/alert', { color: 'error', message: 'Ошибка при остановке профиля, см. консоль' });
      } finally {
        this.loader = false;
      }
    },

    async restore(data) {
      try {
        this.loader = true;

        const response = await this.api.post('/admin/browser_profiles/archive/restore', { ids: [data.id] });

        if (response.status === 200) {
          this.browserProfileRestoringProfilesDialog = false;
          await this.$store.dispatch('main/alert', { color: 'success', message: 'Профиль восстановлен' });
          await this.$store.dispatch('browserProfiles/loadBrowserProfiles', {
            page: this.pagination.currentPage,
            itemsPerPage: this.pagination.perPage,
            searchUser: this.topbarSearch.searchInputUser,
            searchName: this.topbarSearch.searchInputName,
            env: this.env,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        this.loader = false;
        this.browserProfileRestoringProfilesDialog = false;
      }
    },

    showData(data) {
      this.browserProfileData = data;
      this.browserProfileDataDialog = true;
    },

    showRestoreBrowserProfileDialog(data) {
      this.browserProfileData = data;
      this.browserProfileRestoringProfilesDialog = true;
    },
  },
};
</script>
